import React, { Component } from 'react';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import CKEssentials from '@ckeditor/ckeditor5-essentials/src/essentials';
import CKUploadAdapter from '@ckeditor/ckeditor5-adapter-ckfinder/src/uploadadapter';
import CKAutoformat from '@ckeditor/ckeditor5-autoformat/src/autoformat';
import CKBold from '@ckeditor/ckeditor5-basic-styles/src/bold';
import CKItalic from '@ckeditor/ckeditor5-basic-styles/src/italic';
import CKBlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote';
import CKCKFinder from '@ckeditor/ckeditor5-ckfinder/src/ckfinder';
import CKEasyImage from '@ckeditor/ckeditor5-easy-image/src/easyimage';
import CKHeading from '@ckeditor/ckeditor5-heading/src/heading';
import CKImage from '@ckeditor/ckeditor5-image/src/image';
import CKImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption';
import CKImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle';
import CKImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar';
import CKImageUpload from '@ckeditor/ckeditor5-image/src/imageupload';
import CKIndent from '@ckeditor/ckeditor5-indent/src/indent';
import CKLink from '@ckeditor/ckeditor5-link/src/link';
import CKList from '@ckeditor/ckeditor5-list/src/list';
import CKMediaEmbed from '@ckeditor/ckeditor5-media-embed/src/mediaembed';
import CKParagraph from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import CKPasteFromOffice from '@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice';
import CKTable from '@ckeditor/ckeditor5-table/src/table';
import CKTableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar';
import CKBase64Uploader from '@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter';

const config = {
	plugins: [ 
		CKEssentials, CKUploadAdapter, CKAutoformat, CKBold, CKItalic, CKBlockQuote, CKCKFinder,
		CKEasyImage, CKHeading, CKImage, CKImageCaption, CKImageStyle, CKImageToolbar, CKImageUpload,
		CKIndent, CKLink, CKList, CKMediaEmbed, CKParagraph, CKPasteFromOffice, CKTable, CKTableToolbar,
		CKBase64Uploader 
	],
	toolbar: {
		items: [
			'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', '|',
			'indent', 'outdent', '|', 'imageUpload', 'blockQuote', 'insertTable', 'mediaEmbed',
			'undo', 'redo'
		]
	},
	image: {
		toolbar: [
			'imageStyle:full', 'imageStyle:side', '|', 'imageTextAlternative'
		]
	},
	table: {
		contentToolbar: [
			'tableColumn', 'tableRow', 'mergeTableCells'
		]
	},
};

class Editor extends Component {
    onChange = (event, editor) => {
        this.props.onChange({ target: { 
            name: this.props.name,
            value: editor.getData()
        }});
    }

    render = () => (
        <CKEditor editor={ClassicEditor} config={config} data={this.props.value} onChange={this.onChange} />
    )
}

export default Editor;